.container {
  text-align: center;
  background-color: #fff;
}

.document {
  display: inline-block;
  max-width: 880px;
  padding: 36px 12px 36px 12px;
  text-align: left;
}
@primary-color: #0f4c81;