.app-logo {
  line-height: 96px;
  height: 100%;
  width: 96px;
  fill: #0f4c81;
  &.white{
    fill: #fff;
  }
}

@primary-color: #0f4c81;