.admin-logo {
  line-height: 49px;
  height: 100%;
  width: 96px;
  fill: #0f4c81;
  float: left;
  width: 120px;
  height: 31px;
  margin: 10px 24px 16px 0;
  &.white{
    fill: #fff;
  }
  svg{
    height: 42px;
  }
}

@primary-color: #0f4c81;