@import "~antd/dist/antd.less";

// https://ant.design/docs/react/customize-theme
@primary-color          : #0f4c81;//@blue-6;
.center-text{
  text-align: center;
}
.center-block{
  margin: 0px auto;
}
body {
  font-family: 'Roboto', sans-serif;
  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }
  .ant-input-affix-wrapper {
    .ant-input {
      min-height: 0;
    }
  }
  background-color: #efefef;
}

.ant-input{
  padding: 10px 15px;
}

.ant-btn.ant-dropdown-trigger.full{
  text-align:left;
  padding: 10px 15px;
  height: auto;
  .anticon.anticon-down{
    float: right;
    margin-top: 5px;
  }
}

.ant-tabs-nav .ant-tabs-tab{
  height: auto;
}
h3{
  margin-bottom: 0em;
}
.no-padding .ant-card-body{
  padding: 0px;
  .ant-tabs-nav-wrap{
    .ant-tabs-nav{
      display: table;
      table-layout: fixed;
      width: 100%;
      .ant-tabs-ink-bar{
        z-index: 0;
        height: 100%;
      }
      .ant-tabs-tab{  
        &.ant-tabs-tab-active{
          color: #fff;
        }
        display: table-cell;
        flex-basis: 100%;
        width: 100%;
        text-align: center;
      } 
    }
  }
  .ant-tabs-tabpane{
    padding: 12px;
    padding-top: 0px;
    
  }
}

.no-padding .ant-tabs-bar{
  margin-bottom: 24px;
}
.no-padding .ant-card-body .ant-tabs-tabpane{
  padding: 24px;
  padding-top: 0px;
}
.pointer{
  cursor: pointer;
}
.danger-color{
  color: @error-color;
}
.warning-color{
  color: @warning-color;
}
.primary-color{
  color: @primary-color;
}

.ant-btn.ant-btn-primary:not(button){
  line-height: 29px;
  text-decoration: none;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  height: auto;
}
.ant-select-show-search.ant-select-auto-complete{
  height: auto;
}
.text-center{
  text-align: center;
}

.ant-calendar-picker-input.ant-input{
  padding: 4px 2px;
  height: 43px;
  .ant-calendar-range-picker-separator{
    height: 26px;
    vertical-align: middle;
  }
}

.ant-select .ant-select-selection--single{
  height: 40px;
  .ant-select-selection__rendered {
    margin-left: 20px;
    .ant-select-selection-selected-value{
      line-height: 38px;
    } 
  }
}

.ant-radio-group-vertical{
  .ant-radio-button-wrapper:not(:first-child)::before{
    width: 0px;
  }
  .ant-radio-button-wrapper{
    width: 100%;
    border-left: 1px solid #d9d9d9;
    box-shadow: none;
    &.ant-radio-button-wrapper-checked{
      border-left: 1px solid @primary-color;
      &::before{
        width: 0px !important;
      }
      background: @primary-color;
      color: #fff;
    }
    &::before{
      width: 0px;
    }
    &:not(:last-child){
      border-bottom: 0px;
    }
    &:first-child{
      border-radius: 4px 4px 0px 0px;
    }
    &:last-child{
      border-radius: 0px 0px 4px 4px;
    }
  }
}
.cover-input{
  position: relative;
  display: inline-block;
  .cover{
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: absolute;
  }
}

.no-padding .ant-card-body .ant-tabs-nav-wrap .ant-tabs-nav .ant-tabs-ink-bar {
  z-index: 0;
  height: 100%;
  width: 50% !important;
}
.no-padding .ant-card-body .ant-tabs-nav-wrap .ant-tabs-nav .ant-tabs-tab {
  display: inline-block;
  flex-basis: 50%;
  width: 50%;
  text-align: center;
  padding: 15px;
  margin: 0px;
  z-index: 1;
}

.display-block{
  display: inline-block;
  margin: 0px auto;
}

.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}
.ant-select-dropdown-menu-item-active{
  background-color: #0f4c81;
  color: #fff;
}

.ant-select-selection__placeholder{
  line-height: 28px;
  height: 28px;
}

.ant-tabs-tab-btn{
  padding: 0px 10px;
}

.ant-select-show-search.ant-select .ant-select-selector{
  padding: 10px 20px;
  padding-left: 0px;
  .ant-select-selection-item{
    padding-right: 20px;
  }
  .ant-select-arrow{
    padding-right: 24px;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector input.ant-select-selection-search-input{
  height: auto;
  padding: 14px;

}

.ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search{
  width: 100%;
}

.ant-select-selector{
  padding-left: 10px
}

.hero .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
  padding-left: 1.5em;
}


.ant-select.ant-select-auto-complete.ant-select-single.ant-select-show-search{
  .ant-select-selection-placeholder{
    padding-left: 2em;
  }
}

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 0px;
}

.center-text{
  text-align: center;
}
@primary-color: #0f4c81;