.landing-header.ant-layout-header {
  background: none !important;
  padding: 0px;
  height: 64px;
  z-index: 5 !important;
  position: relative;
  .logo {
    float: left;
    margin-left: 30px;
  } 
  .ant-menu {
    float: right; 
    border: none;
    line-height: 64px;
  }
  .ant-menu-item {
    border: none;
    margin-right: 20px;
    font-weight: bold;
  }
  .ant-menu-item:hover {
    border: none !important;
  }
  .ant-menu-item-active {
    border-bottom: none !important;
  }
}
@primary-color: #0f4c81;