.login-page {
  padding: 112px 0 24px;
  min-height: 100%;
  .background {
    position: fixed; 
    top: 0; 
    left: 0; 
    min-width: 100%;
    min-height: 100%;
    // z-index: 1;
  }
  .login-form {
    .header {
      margin: 0 0 24px 0;
    }
    max-width: 468px;
    margin: 0 auto;
    text-align: center;
    .ant-input {
      min-height: 0;
    }
  }
}
@primary-color: #0f4c81;