.more-options{
    position: relative;
    display: inline-block; 
    width: 180px; 
    text-align: center;
    background-color: #0f4c81;
    height: 51px;
    vertical-align: top;
    line-height: 51px;
    color: #fff;
    cursor: pointer;
}
.filters{
    width: 355px;
    label{
        text-align: center;
        width: 100%;
        display: block;
        font-weight: bold;
    }
    .selectivityLevelTile{
        margin-left: 10px;
    }
    .tile{
        // height: 140px;
        // line-height: 90px;
        width: 255;
    }
    .ant-form.ant-form-horizontal{
        width: 355px;
    }
    display: inline-block;
    vertical-align: top;
}
.filtered-results{
    width: calc(100% - 355px);
    display: inline-block;
    .ant-list-bordered .ant-list-item{
        cursor: pointer;
    }
}
// :global(text)
@primary-color: #0f4c81;