// .admin-sider .ant-layout-sider .ant-layout-sider-dark{
  //   min-width: auto !important;
  //   max-width: auto !important;
  //   // width: auto !important;
  //   // width: 356px !important;
  // }
  // @media 
.main-body{
  max-width: 1250px;
  width: 100%;
  margin: 0px auto;
}
@media only screen and (max-width: 800px)  {
  .admin-page .ant-layout-sider{
    width: 100% !important;
    flex: 0 0 100%  !important;
    max-width: 100%  !important;
    min-width: 100%  !important;
    display: block !important;
    padding-bottom: 200px;
  }
  .ant-layout{
    display: block !important;
    
    .ant-steps{
      font-variant: tabular-nums;
      line-height: 1.5;
      color: rgba(0, 0, 0, 0.65);
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 0;
      width: 100%;
      display: block;

      .ant-steps-item{
        width: 100% !important;
        display: block !important;
        overflow: visible !important;
        white-space: normal !important;
        height: 31px;
        .ant-steps-item-title:after{
          display: none;
        }
      }
    }
  }
}

.admin-page {
  .admin-sider {
    .logo {
      padding-left: 50px;
      height: 65px;
      background-color: #fff;
      border-bottom: 1px solid #dbdddd;
      .app-logo {
        fill: #1F6EBE;
      }
    }
    .menu {
      padding: 16px 0;
    }
    form {
      // .ant-divider-horizontal.ant-divider-with-text{
      //   color: #fff;
      // }
      padding: 19px;
    }
  }

  .admin-header {
    background-color: #fff;
    font-size: 20px;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
    z-index: 1000;
    .anticon {
     line-height: 64px;
     padding: 0 24px;
    }
    .ant-menu {
      margin-right: 24px;
      border-bottom: 0px;
      float: right;
      background: transparent;
      color: rgb(104, 158, 211);
      
      .ant-menu-item-selected{
        // color: rgb(48, 97, 146);
        background-color: #efefef;
        border-top: 0px;
        border-bottom: 0px;
        color: rgb(77, 137, 197);
      }
      .ant-menu-item {
        border-color: #689ed3;
        border-bottom: none;
      }
      .ant-menu-item:hover {
        
        color: #0f4c81;
      }
    }
    .ant-menu.button .ant-menu-item:hover {
      color: #0f4c81;
    }
  }
}
.admin-page .ant-layout-sider{
  background-color: #ffffff;//#688bcc; //#14283A;
  color: #000
}


@media only screen and (max-width: 992px)  {
  body .xs-hidden{
    display: none;
  }
}
.ant-radio-group-vertical .ant-radio-button-wrapper{
  overflow: hidden;
}


.landing-header.ant-layout-header {
  background-color: rgba(255, 255, 255, .5);
  .ant-menu.ant-menu-light{
    background: transparent;
    .ant-menu-item a{
      color: #0f4c81;
    }
  }
}
.logo.header-logo{
  position: absolute;
  left: 50px;
  top: 7px;
  height: 50px;
}

.ant-select{
  min-width: 200px;
}
@primary-color: #0f4c81;