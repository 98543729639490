.ant-layout {
  background: none !important;
}
.hero {
  .app-logo{
    margin-top: 10px;
    margin-left: 5px;
  }
  height: 100vh;
  width: 100%;
  // background: url("../../assets/hero-img.jpg") no-repeat center center fixed;
  background-image: #fff;//radial-gradient(circle,#c0d3e7, #8cb0d4);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .ant-btn{
    margin-top: 5px;
  }
}
.main {
  width: 100%;
  height: ~"calc(100% - 96px)"; 
  display: flex;
  justify-content: center;
  .main-content {
    max-width: 550px;
    max-height: 350px;
    margin-top: 15vh; 
    width: 100%;
  } 
  h1 {
    font-weight: lighter;
    font-size: 3em;
    padding-bottom: 25px;
  }
  .ant-btn {
    background-color: #0f4c81;
    color: #fff;
    font-weight: lighter;
    font-size: 16px;
    width: 100%;
    height: 41px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); 
    border: none;
  }
  .ant-select-auto-complete {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); 
    width: 100%;
    font-size: 16px;
  }
  .ant-select-selection__rendered {
    margin-left: 0px !important; 
  }
}

@media screen
  and (max-width: 768px)
  and (orientation: landscape) {
  .main-content {
    margin-top: 0 !important;
  }
}
.ant-select-dropdown-menu-item-active {
  color: rgba(0, 0, 0, 0.65) !important; 
}

label.errors{
  color: #aa0000;
  font-size: 1.25em;
}

.ant-select-show-search.ant-select .ant-select-selector .ant-select-selection-item{
  padding-left: 1.8em;
}

#root .ant-card{
  margin: 6px;
  border-radius: 6px;
}

#root .ant-btn {
  border-radius: 4px;
}

.invitation-collect{
  .ant-btn:hover {
    border: 1px solid #d9d9d9;
  }
}
@primary-color: #0f4c81;