.lights{
    width: 100%;
    height: 20px;
    display: inline-block;
    position: relative;
    margin-top: 30px;
    // padding-left: 20px;
    // padding-right: 20px;
    .light{
        display: block;
        position: relative;
        display: inline-block;
        width: 20%;
        height: 30px;
        padding: 0px;

        &.low{
            border-radius: 18px 0px 0px 18px;
            overflow: hidden;        
            background-color: #e5472a;
        }
        &.medium-low{
            background-color: #f19d3a;
        }
        &.medium{
            background-color: #f6ce4c;
        }
        &.medium-high{
            background-color: #a0cc40;
        }
        &.high{
            border-radius: 0px 18px 18px 0px;
            overflow: hidden;       
            background-color: #5dbb3d;
        }
    }
    .indicator{
        position: absolute;
        text-align: center;
        font-size: 1.5em;
        padding: 0px;
        top: 1px;
        background-color: rgb(255, 255, 255);
        border: 2px solid rgb(183, 183, 183);
        border-radius: 50%;
        width: 28px;
        height: 28px;
    }
    padding: 0px;
}

@media (min-width: 800px) {
    .lights{
        // margin: 28px 0px; 
        // .light{
        //     width: 20%;
        // }
        // .indicator{
        //     width: 25px;
        //     height: 25px;
        //     margin-bottom: -21px;
        // }
    }
    /* CSS that should be displayed if width is equal to or less than 800px goes here */
}

// :global(text)
@primary-color: #0f4c81;