.login-page {
  padding: 112px 0 24px;
  min-height: 100%;
  .background {
    position: fixed; 
    top: 0; 
    left: 0; 
    min-width: 100%;
    min-height: 100%;
    // z-index: 1;
  }
  .login-form {
    .header {
      margin: 0 0 24px 0;
    }
    max-width: 468px;
    margin: 0 auto;
    text-align: center;
    .ant-input {
      min-height: 0;
    }
  }
}

.login {
  label{
    font-weight: 800;
  }
  .or-border{
    top: 0px;
    border-left: 1px solid #aaa;
    height: 20em;
    display: inline-block;
    position: absolute;
  }
  .or {
    top: 10em;
    left: 50%;
    position: absolute;
    display: inline-block;
    border-radius: 50%;
    padding: 10px;
    border: 1px solid #aaa;
    margin-left: -1.55em;
    background-color: #fff;
    margin-top: -4em;
  }
}
@primary-color: #0f4c81;