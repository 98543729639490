
.school-score{
    position: relative;
    display: inline-block;
    text-align: left;
    border: 1px solid #f0f0f0;
    background-color: #fff;
    padding: 1.44em;
    width: calc(100% - 50px);
    margin-top: 8px;
    margin-left: 0px;
    margin-right: 0px;
    .score-name{
        left: 28px;
        top: 10px;
        position: absolute;
        transform-origin: 0 0;
        white-space: nowrap;
        font-size: 1.8em;
    }
}
.results{
    max-width: 1250px;
    padding: 1em;
    padding-left: 0px;
    padding-right: 0px;
    .remove-school{
        font-size: 2em;
        position: absolute;
        right: .5em;
        top: .1em;
        cursor: pointer;
        color: #888;
    }
}
@media (min-width: 800px) {
    .results{
        margin: 0px auto;
        
        //     margin: 10px;
        //     padding: 30px;
        //     padding-bottom: 0px;
        //     .score-name{
        //         left: 38px;
        //         font-size: 1.5em;
        //     }
        // }
    }
}
@primary-color: #0f4c81;