.ant-dropdown-trigger.scaffold{
    .inner-button{
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 1em;
    }
    .anticon.anticon-down{
        position: absolute;
        top: 8px;
        right: 10px;
        font-size: 16px;
        font-weight: bold;
    }
}

.ant-dropdown-menu{
    max-height: 400px;
    overflow-y: auto;
}
@primary-color: #0f4c81;